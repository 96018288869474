import { autoinject, inlineView } from 'aurelia-framework';
import { NotificationService } from '$services/notificationService';
import { Router } from 'aurelia-router';
import { ControllerService } from '$services/controllerService';
import { Root } from 'react-dom/client';
import { ReactWrapper } from '$pages/common/react-wrapper';
import ControllerDetails, {
  IControllerDetailsProps,
} from '$pages/common/controllers/controllerdetails-react/controller-details';
import MuiThemeProvider from '$components/mui-theme-provider/mui-theme-provider';
import { ProvideControllerCommandService } from '$lib/hooks/useControllerCommandService';

export interface ControllerDetailsURLParams {
  controllerId: string;
}

@autoinject()
@inlineView(`<template><div ref='react'></div></template>`)
export class SiteDetailsControllerDetailsWrapper extends ReactWrapper<
  IControllerDetailsProps,
  ControllerDetailsURLParams
> {
  react: HTMLElement | null;
  parent: HTMLElement | null;
  props: ControllerDetailsURLParams | undefined;
  reactRoot: Root | null;

  constructor(
    notificationService: NotificationService,
    router: Router,
    controllerService: ControllerService
  ) {
    super(
      ControllerDetails,
      (params) => ({
        controllerId: parseInt(params.controllerId ?? ''),
        showMenubar: false,
        showAccessCard: false,
        notificationService: notificationService,
        aureliaRouter: router,
        aureliaControllerService: controllerService,
      }),
      [MuiThemeProvider, ProvideControllerCommandService]
    );
  }
}
