import { RouterConfiguration } from 'aurelia-router';
import { PLATFORM } from 'aurelia-pal';
import { routeNames } from '../../../../config';
import './sitedetailscontrollers.css';

export class SiteDetailsControllers {
  configureRouter(config: RouterConfiguration) {
    config.map([
      {
        route: [''],
        name: 'sitedetailscontrollerlist',
        moduleId: PLATFORM.moduleName(
          './modules/sitedetailscontrollerlist/sitedetailscontrollerlist',
          'sitedetails-controllerlist'
        )
      },
      {
        route: ':controllerId',
        name: routeNames.sitedetailsControllerDetails,
        href: '',
        moduleId: PLATFORM.moduleName(
          './modules/sitedetailscontrollerdetails/sitedetailscontrollerdetails.react'
        )
      },
      {
        route: ':controllerId/profile/:profileId/:historyId?',
        name: routeNames.sitedetailsProfileDetails,
        moduleId: PLATFORM.moduleName(
          'pages/common/controllers/controller-profile-editor/controller-profile-editor-wrapper',
          'sitedetails-profiledetails'
        )
      }      
    ]);
  }
}
